@use '@angular/material' as mat;

@import 'https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round|Material+Icons+Sharp';
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;800&display=swap');

$my-typography: mat.define-typography-config(
  $font-family: 'Manrope',
  $headline-1:
    mat.define-typography-level(112px, 112px, 800, $letter-spacing: -0.0134em),
  $headline-2:
    mat.define-typography-level(56px, 56px, 800, $letter-spacing: -0.0089em),
  $headline-3:
    mat.define-typography-level(45px, 48px, 800, $letter-spacing: 0em),
  $headline-4:
    mat.define-typography-level(34px, 40px, 800, $letter-spacing: 0.0074em),
  $headline-5:
    mat.define-typography-level(34px, 40px, 800, $letter-spacing: 0.0074em),
  $headline-6:
    mat.define-typography-level(20px, 40px, 800, $letter-spacing: 0.0074em),
  $subtitle-1:
    mat.define-typography-level(20px, 28px, 800, $letter-spacing: 0.0067em),
  $subtitle-2:
    mat.define-typography-level(14px, 28px, 800, $letter-spacing: 0.0094em),
  $body-1:
    mat.define-typography-level(14px, 20px, 400, $letter-spacing: 0.0179em),
  $body-2:
    mat.define-typography-level(14px, 20px, 400, $letter-spacing: 0.0179em),
  $button:
    mat.define-typography-level(13.333px, 14px, 800, $letter-spacing: 0.0179em),
  $caption:
    mat.define-typography-level(12px, 20px, 400, $letter-spacing: 0.0333em),
);

$dark-text: #1e2b4e;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);

$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);

$mat-primary: (
  main: #1e2b4e,
  lighter: #1a6ba8,
  darker: #1e2b4e,
  200: #228cdb,
  contrast: (
    main: $light-primary-text,
    lighter: $light-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

$mat-accent: (
  main: #63e776,
  lighter: #a1f1ad,
  darker: #378142,
  200: #63e776,
  contrast: (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000,
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $my-typography,
    density: 0,
  )
);

@include mat.core();
@include mat.all-component-themes($theme);
@include mat.all-component-typographies($my-typography);
