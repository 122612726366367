@import 'techspert-theme.scss';

@import 'ngx-toastr/toastr';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  // Angular defaults

  ul,
  ol {
    list-style: revert;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
  }

  a {
    text-decoration: underline;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  color: #1e2b4e;
}

* {
  box-sizing: border-box;

  &::-webkit-scrollbar {
    background-color: #fff;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }
}

:root {
  --font-color: #1e2b4e;
  --spacing-m: 1.25rem; //20px equiv
}

.mat-mdc-card-content {
  padding: 4px;
}

.custom-dialog-container .mat-dialog-container {
  overflow: visible;
}

.omnisearch-item {
  .omnisearch-result-tag {
    color: #1e2b4e;
    font-weight: 800;
  }
}

#toast-container {
  font-size: 16px;
}

.mdc-notched-outline__leading,
.mdc-notched-outline__trailing {
  border-color: #eaebed !important;
}

.mdc-notched-outline__notch {
  border-color: #eaebed !important;
  border-right: none !important;
  border-left: none !important;
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
  font-weight: 400;
}
.mat-mdc-text-field-wrapper {
  background: #f7f8fa;
}

.mat-mdc-select {
  color: rgba(0, 0, 0, 0.05);
  font-weight: 400;

  .mat-mdc-select-trigger {
    .mat-mdc-select-value {
      .mat-mdc-select-value-text {
        display: flex;
        align-items: center;
        height: 100%;
        font-weight: 400;
      }
      .mat-mdc-select-placeholder {
        color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}
.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: block !important;
}

.mat-group-custom-style {
  .mat-mdc-tab {
    .mdc-tab-indicator__content--underline {
      border-color: #228cdb;
    }
  }
  .mdc-tab--active {
    mat-icon {
      color: #228cdb;
    }
  }
  .mat-mdc-tab-body-wrapper {
    padding: 16px;
  }
}

.mat-group-assistant-style {
  .mat-mdc-tab-body {
    height: 100%;
  }
  .mat-mdc-tab-body-wrapper {
    min-height: calc(100% - 50px);
  }
}

.mat-mdc-tab-label-container {
  background: #f7f8fa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.mat-mdc-tab-group .mat-mdc-tab .mdc-tab__content {
  .mdc-tab__text-label {
    color: #1e2b4e;
    font-weight: 800;
    font-size: 14px;
    opacity: 0.6;
    letter-spacing: 0.02rem;
  }
}
.mat-mdc-tab-group .mdc-tab--active .mdc-tab__content {
  .mdc-tab__text-label {
    color: #1e2b4e;
    font-weight: 800;
    font-size: 14px;
    opacity: 1;
    letter-spacing: 0.02rem;
  }
}
.mat-mdc-tab-group .mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__content {
  pointer-events: auto;
}

.mat-sort-header {
  .mat-sort-header-container {
    .mat-sort-header-content {
      color: #0000008a;
    }
  }
}

.mat-stroked-button,
.mat-flat-button {
  padding: 16px 0;
  font-weight: 800;

  &:disabled {
    mat-icon {
      filter: opacity(0.5);
    }
  }
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: inherit !important;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: #fafafa !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before {
  background-color: #00000061 !important;
}
.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background-color: #63e7768a !important;
}

.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--unselected:enabled
  .mdc-switch__handle::after {
  background: #fafafa !important;
}
.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled
  .mdc-switch__handle::after {
  background: #63e776 !important;
}

.mdc-switch__icons {
  display: none;
}

.mat-horizontal-stepper-wrapper {
  height: 100%;

  .mat-horizontal-content-container {
    height: 100%;
  }

  .mat-horizontal-stepper-content {
    height: 100%;
  }
}

.mat-step-header {
  .mat-step-icon-selected {
    background-color: #63e776;
  }
}

.right .mat-sort-header-container {
  direction: rtl;
}

.txpPink {
  color: #ffb3c3;
}

.secondaryColor {
  color: #63e776;
}

.secondaryColorBG {
  background-color: #63e776;
}

.primaryColorBG {
  background-color: #1e2b4e !important;
}

.primaryColor {
  color: #1e2b4e;
}

.primaryColor-border {
  border-color: #1e2b4e !important;
}

.spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.markdown {
  .marked-h2 {
    margin: 0;
  }

  .marked-ul {
    margin: 0;
  }
}

.notif {
  p {
    margin-bottom: 0;
  }
}

.highlight {
  background-color: rgba(yellow, 0.5);
}

.code-sample-body {
  position: relative;
  background-color: #f1f2f4;
  border-radius: 4px;
  margin: 32px 8px;

  pre {
    font-size: 14px;
    padding: 12px 16px;
    text-wrap: wrap;
    font-family: monospace;
    color: #6f7c91;
    max-height: 200px;
    overflow-y: scroll;
  }

  .button-container {
    position: absolute;
    top: 8px;
    right: 24px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    svg {
      width: 16px;
      height: 16px;
      fill: #8e8ea0;
      cursor: pointer;

      &:hover {
        fill: #1e2b4e;
      }
    }
  }
}

.thread {
  h1 {
    font-size: 20px;
    font-weight: 800;
  }
  h2 {
    font-size: 16px;
    font-weight: 800;
  }
  h3 {
    font-size: 14px;
    font-weight: 800;
  }
}
